import Logo from 'assets/images/logo.svg';
import logIn from 'assets/images/icons/black-chrome.svg';
import hoverLoginImage from 'assets/images/icons/yellow_chrome.svg';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import CrossSign from 'assets/images/icons/cross_sign.svg';
import Icon from '../Icon';

const handlePricing = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    window.location.href = '/pricing';
};

const handleHowItWorks = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    window.location.href = '/#how_it_works';
};

const Sidebar = ({ isOpen, onClose }) => (
    <SidebarContainer isOpen={isOpen}>
        <ImageClose src={CrossSign} onClick={onClose} />
        <YellowButtonStyled
            onClick={() => {
                window.location.href =
                    'https://chromewebstore.google.com/detail/spry/kioknbjoaogjjfmbbiafkagepdhhfjje';
            }}
        >
            <ImageLogIn src={logIn} />
            Add to Chrome&nbsp; <span className="thinText"> It's free</span>
        </YellowButtonStyled>
        <TextCont>
            <Text onClick={handleHowItWorks}>How it works?</Text>
            <Text onClick={handlePricing}>Pricing</Text>
        </TextCont>
        <ImagesContainer>
            <ButtonOverlay
                onClick={() => {
                    window.open('https://x.com/SpryPlan', '_blank');
                }}
            >
                <Icon type="twitterIcon" size="26" />
            </ButtonOverlay>
            <ButtonOverlay
                onClick={() => {
                    window.open(
                        'https://www.linkedin.com/company/101826573',
                        '_blank'
                    );
                }}
            >
                <Icon type="linkedinIcon" size="26" />
            </ButtonOverlay>
        </ImagesContainer>
        <TextEmail>Stay tuned to our updates and special offers</TextEmail>
        <InputButtonContainer>
            <EmailInput type="email" placeholder="Your email" />
            <SubmitButton>Subscribe</SubmitButton>
        </InputButtonContainer>
    </SidebarContainer>
);

const InputButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    width: 100%;
    height: 100%;
`;
const ButtonOverlay = styled.div`
    border: 1px solid #cacacc;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    justify-content: center;
    display: flex;
    align-items: center;
    &:hover {
        border: 1px solid #474747;
    }
`;

const EmailInput = styled.input`
    width: 85%;
    max-width: 334px;
    height: 40px;
    border: solid #919191;
    border-radius: 6px;
    padding: 4px 8px;
    font-size: 14px;
    font-family: 'Inter';
    box-sizing: border-box;
    color: #919191;
`;

const SubmitButton = styled.button`
    width: 85%;
    height: 40px;
    background-color: black;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
    margin-bottom: 24px;

    &:hover {
        background-color: #474747;
    }
`;

const TextEmail = styled.div`
    margin-top: 152px;
    font-family: 'SharpGrotesk-Light';
    font-size: 18px;
    text-align: center;
`;

const ImagesContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 162px;
`;

const Image = styled.img``;

const TextCont = styled.div`
    margin-top: 40px;
    font-family: 'Inter';
    font-size: 18px;
    justify-content: center;
    text-align: center;
`;

const SidebarContainer = styled.div`
    position: fixed;
    right: 0;
    top: 0;
    width: 335px;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.5);
    transform: ${(props) =>
        props.isOpen ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.3s ease-in-out;
    z-index: 200;
    overflow-y: auto;
`;

const ImageClose = styled.img`
    position: absolute;
    top: 30px;
    right: 30px;
    margin-top: 8px;
    margin-right: 8px;
    cursor: pointer;
`;

const YellowButtonStyled = styled.button`
    margin-top: 92px;
    font-weight: 600;
    background-color: #ffcf40;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'Inter';
    color: var(--neutral-80);

    .thinText {
        font-weight: 400;
    }

    padding: 12px 16px;
    min-height: 40px;
    width: 85%;
    margin-left: auto;
    margin-right: auto;

    &:hover {
        background-color: #e3b023;
    }

    @media (max-width: 1200px) {
        min-height: 40px;
        padding: 12px 16px;
    }
`;

const Text = styled.div`
    margin-bottom: 32px;
    cursor: pointer;
`;

const Header = () => {
    const location = useLocation();
    const isPricingPage =
        (location.pathname === '/pricing') |
        (location.pathname === '/privacy_policy') |
        (location.pathname === '/terms_of_use') |
        (location.pathname === '/how_it_works');
    const definitelyPricingPage = location.pathname === '/pricing';
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [loginImageSrc, setLoginImageSrc] = useState(logIn);
    const defaultLoginImage = logIn;
    const [scrolled, setScrolled] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const handleScroll = () => {
        const scrollPos = window.scrollY;
        if (scrollPos > 0) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const getGoogleIconType = () => {
        if (isHovered && !scrolled && !isPricingPage) {
            return 'YellowChromeIcon';
        } else if (scrolled || isPricingPage) {
            return 'BlackChromeIcon';
        } else {
            return 'WhiteChromeIcon';
        }
    };

    const getLinesIconType = () => {
        if (scrolled || isPricingPage) {
            return 'ThreeBlackLines';
        }
        return 'ThreeWhiteLines';
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleLogoClick = () => {
        window.location.href = '/';
    };

    return (
        <>
            <StyledHeader scrolled={scrolled} isWhiteBackground={isPricingPage}>
                <StyledDiv>
                    <StyledImage onClick={handleLogoClick} src={Logo} />
                    <TextContainer>
                        <HeaderText
                            href="/#how_it_works"
                            scrolled={scrolled}
                            isWhiteBackground={isPricingPage}
                            style={{ cursor: 'pointer' }}
                        >
                            How it works?
                        </HeaderText>
                        <HeaderText
                            href="/pricing"
                            scrolled={scrolled}
                            style={{
                                cursor: 'pointer',
                                borderBottom: definitelyPricingPage
                                    ? '2px solid black'
                                    : 'none',
                                padding: '2px',
                            }}
                            isWhiteBackground={isPricingPage}
                        >
                            Pricing
                        </HeaderText>
                    </TextContainer>
                </StyledDiv>
                <ButtonContainer>
                    <StyledButton
                        className="add-to-chrome-header"
                        isWhiteBackground={isPricingPage}
                        scrolled={scrolled}
                        variant="login"
                        style={{ width: '227px' }}
                        onClick={() => {
                            window.location.href =
                                'https://chromewebstore.google.com/detail/spry/kioknbjoaogjjfmbbiafkagepdhhfjje';
                        }}
                        onMouseEnter={() => {
                            setIsHovered(true);
                            setLoginImageSrc(hoverLoginImage);
                        }}
                        onMouseLeave={() => {
                            setIsHovered(false);
                            setLoginImageSrc(defaultLoginImage);
                        }}
                    >
                        <Icon
                            type={getGoogleIconType()}
                            size="20"
                            style={{ marginRight: '10px' }}
                        />
                        Add to Chrome &nbsp;
                        <span className="thinText"> It's free</span>
                    </StyledButton>
                    <SmallDeviceButton
                        onClick={toggleSidebar}
                        scrolled={scrolled}
                        variant="alternative"
                    >
                        <Icon
                            type={getLinesIconType()}
                            style={{ marginRight: '10px' }}
                        />
                    </SmallDeviceButton>
                </ButtonContainer>
            </StyledHeader>
            <Sidebar
                isOpen={isSidebarOpen}
                onClose={() => setIsSidebarOpen(false)}
            />
        </>
    );
};

const StyledHeader = styled.div`
    background-color: ${(props) =>
        props.isWhiteBackground
            ? 'white'
            : props.scrolled
              ? 'white'
              : '#140027'};
    padding-bottom: ${(props) => (props.scrolled ? '20px' : '0px')};
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 76px;
    padding: 10px 0;
    border-bottom: ${(props) =>
        props.scrolled ? '1px solid #E3E3E5' : '1px solid transparent'};

    @media (max-width: 1200px) {
        background-color: ${(props) =>
            props.isWhiteBackground
                ? 'white'
                : props.scrolled
                  ? 'white'
                  : '#140027'};
        justify-content: space-between;
        height: 20px;
        padding-top: 50px;
        max-width: 100%;
    }
`;

const StyledImage = styled.img`
    margin-left: 64px;
    width: 37px;
    cursor: pointer;

    @media (max-width: 1200px) {
        margin-top: -56px;
        margin-left: 18px;
    }
`;

const TextContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 32px;
    margin-left: 64px;

    @media (max-width: 1200px) {
        margin-left: 0;
        gap: 16px;
    }
`;

const HeaderText = styled.a`
    color: ${(props) =>
        props.scrolled || props.isWhiteBackground ? 'black' : 'white'};
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    font-family: 'Inter';

    &:hover {
        color: ${(props) =>
            props.isWhiteBackground || props.scrolled ? '#737373' : '#FFCF40'};
    }

    @media (max-width: 1200px) {
        display: none;
    }
`;

const StyledDiv = styled.div`
    display: flex;
    flex-direction: row;
    @media (min-width: 1920px) {
        margin-left: 350px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 16px;
    margin-left: auto;

    @media (max-width: 1200px) {
        justify-content: flex-end;
    }
`;

const StyledButton = styled.button`
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin-right: 64px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    border-radius: 8px;
    height: 40px;
    border: none;
    cursor: pointer;
    transition:
        background-color 0.3s,
        color 0.3s;

    .thinText {
        font-weight: 400;
    }

    color: ${(props) =>
        props.scrolled || props.isWhiteBackground ? 'black' : 'white'};
    background-color: ${(props) =>
        props.scrolled || props.isWhiteBackground ? '#FFCF40' : 'transparent'};
    border: ${(props) =>
        props.scrolled || props.isWhiteBackground ? 'none' : '1px solid white'};

    &:hover {
        border-color: #ffcf40;
        color: ${(props) =>
            props.scrolled || props.isWhiteBackground ? 'black' : '#FFCF40'};
        background-color: ${(props) =>
            props.scrolled || props.isWhiteBackground
                ? '#F3C332'
                : 'transparent'};
    }

    @media (max-width: 1200px) {
        display: none;

        &:hover {
            border-color: initial;
            color: initial;
        }

        background-color: transparent;
        color: white;
    }

    @media (min-width: 1920px) {
        margin-right: 350px;
    }
`;

const ImageLogIn = styled.img`
    width: 20px;
    height: 20px;
    margin-right: 10px;
`;

const SmallDeviceButton = styled.div`
    display: none;

    @media (max-width: 1200px) {
        display: flex;
        border: none;
        margin-top: -38px;
        margin-right: 8px;
        cursor: pointer;
    }
`;

export default Header;
