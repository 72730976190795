import styled from 'styled-components';
import 'assets/fonts.css';
import plans from "./pricingPlan/const";
import PricingCard from "components/pricingPlan/pricingCard";

const PricingContainer = () => {

    return (
        <MainContainer>
            <hr style={{position: 'absolute', top: '20', color: '#E3E3E5'}}/>
            <MainHeader>
                Say goodbye to unproductive meetings!
                <br/>
                Join now for smarter planning and big savings.
            </MainHeader>
            <PricingBox>
                {
                    Object.entries(plans).map(([key, plan]) => (
                        <PricingCard
                            key={key}
                            name={plan.name}
                            price={plan.price}
                            description={plan.description}
                            advantages={plan.advantages}
                            isMostPopular={plan.isMostPopular}
                            showButtonUpdate={false}
                        />
                    ))
                }
            </PricingBox>
            <TaxesText>
                *Taxes may be included regarding to your location
            </TaxesText>
        </MainContainer>
    );
};

export default PricingContainer;

const MainContainer = styled.div`
    background-color: white;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
`;

const MainHeader = styled.div`
    margin-top: 96px;
    margin-bottom: 80px;
    font-family: 'SharpGrotesk-Medium20';
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    color: #1a1a1a;
    text-align: center;
    @media (max-width: 1200px) {
        text-align: start;
        padding: 20px;
    }
`;

const StyledImage = styled.img`
    position: absolute;
    top: -27px;
    left: 33%;
`;

const Button = styled.button`
    padding: 7px 15px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 32px;
    background-color: ${(props) => props.backgroundColor};
    width: 304px;
    margin: 2.5rem;
    margin-bottom: 22px;
    @media (max-width: 1200px) {
        margin: 1.5rem;
        width: 279px;
        height: 40px;
    }
`;

const PricingBox = styled.div`
    display: flex;
    flex-direction: row;
    gap: 32px;
    margin-bottom: 56px;

    @media (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
    }
`;

const MainText = styled.div`
    font-family: 'SharpGrotesk-Medium20';
    font-weight: 500;
    font-size: 40px;
    line-height: 56px;
    margin: 2.5rem;
    @media (max-width: 1200px) {
        margin: 1.5rem;
    }
`;





const TaxesText = styled.div`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #737373;
    margin-bottom: 96px;

    @media (max-width: 1200px) {
        display: none;
    }
`;

const ThinText = styled.div`
    color: #737373;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 2.5rem;
    @media (max-width: 1200px) {
        margin: 1.5rem;
    }
`;

const ListOfFeatures = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    gap: 15px;
    @media (max-width: 1200px) {
        margin: 1.5rem;
    }
`;

const Item = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
`;

const CheckPointText = styled.div`
    color: #474747;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
`;
